<template>
	<v-container fluid>
		<div v-if="isLoading" color="grey lighten-4" class="pa-3">
			<v-row>
				<v-col cols="6" v-for="i in 2" :key="i">
					<v-skeleton-loader
						class="mx-auto"
						width="100%"
						type="card"
					></v-skeleton-loader>
				</v-col>
			</v-row>
		</div>
		<div v-else>
			<v-row v-if="orgs != null" class="mx-0">
				<v-col
					cols="6"
					v-for="org in orgs"
					:key="org.name"
					class="py-0 d-flex"
				>
					<v-card>
						<v-row class="mx-0 pa-4 d-flex flex-row align-center">
							<v-col cols="1" class="pa-0">
								<v-avatar v-if="org.profilePhotoUrl">
									<img :src="org.profilePhotoUrl" />
								</v-avatar>
								<v-avatar v-else>
									<img src="@/assets/img/common/avatar.png" />
								</v-avatar>
							</v-col>
							<v-col cols="11" class="pl-4">
								<h2 class="font-weight-regular">
									{{ org.accountName }}
								</h2>
								<span
									class="
										mdi mdi-check-decagram
										icon
										pl-3
										blue-verified
									"
									v-if="org.state.status === 'VERIFIED'"
								></span>
							</v-col>
						</v-row>
						<v-divider></v-divider>
						<v-card-text>
							<div
								class="
									d-flex
									flex-row
									justify-space-between
									mb-3
								"
							>
								<h1 class="card-section">Bussiness Type :</h1>
								<p>
									{{ org.type }}
								</p>
							</div>
							<div class="d-flex flex-row justify-space-between">
								<h1 class="card-section">
									Bussiness Profile Status :
								</h1>
								<div class="d-flex flex-row">
									<span
										class="
											mdi mdi-check-decagram
											icon
											pr-2
											verified
										"
										v-if="org.state.status === 'VERIFIED'"
									></span>
									<span
										class="
											mdi mdi-alert-decagram
											icon
											pr-2
											unverfied
										"
										v-else
									></span>
									<p>
										{{ org.state.status }}
									</p>
								</div>
							</div>
						</v-card-text>
						<v-divider></v-divider>
						<v-card-actions
							class="px-4 d-flex flex-row justify-end"
						>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										color="#5cbbf6"
										dark
										@click="selectOrg(org)"
										v-bind="attrs"
										v-on="on"
										>Select</v-btn
									>
								</template>
								<span class="tooltip-text"
									>Select This Organisation
								</span>
							</v-tooltip>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
			<v-card v-else class="mb-0 d-flex justify-center" height="500px">
				<div class="align-self-center flex-column">
					<h1>No Organisations found.</h1>
					<p>Please select another account.</p>
				</div>
			</v-card>
		</div>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			orgs: null,
			isLoading: false,
		}
	},
	computed: {
		authUrl: {
			get() {
				return this.$store.getters['gmb/getAuthUrl']
			},
		},
	},
	created() {
		this.getOrgList()
	},
	methods: {
		getOrgList() {
			this.isLoading = true
			const AccountEmail = this.$cookies.get('gmb-account-email')
			const ProjectId = this.$store.state.app.projectId
			return this.axios({
				method: 'post',
				url: `/${ProjectId}/gmb/org`,
				data: { email: AccountEmail },
			})
				.then((response) => {
					this.$nextTick(function () {
						this.orgs = response.data.data.orgs
					})
					this.$store.commit('gmb/orgData', response.data.data.orgs, {
						root: true,
					})
					this.$store.commit('gmb/orgEmail', AccountEmail, {
						root: true,
					})
					this.isLoading = false
				})
				.catch((err) => {
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
					this.isLoading = false
				})
		},
		async selectOrg(org) {
			this.$cookies.set('gmb-selected-org', org.name)
			this.$router.push({
				name: 'gmb.locations',
			})
		},
		login() {
			window.location.href = this.authUrl
		},
	},
}
</script>
<style scoped>
.header {
	border-bottom: 1px solid #eee;
}
.address,
.card-section {
	font-size: 1rem;
}
.icon {
	font-size: 20px;
}
.name:hover {
	color: var(--blue) !important;
}
.name {
	color: black !important;
	text-decoration: none !important;
}
.verified {
	color: #3f51b5;
}
.blue-verified {
	font-size: 24px;
	color: #3f51b5;
}
.website {
	text-decoration: none !important;
	color: var(--blue) !important;
}
.v-card {
	width: 100% !important;
}
.success-modal .heading {
	color: #4daf51;
	font-weight: 400;
	font-size: 1.5rem;
}
.success-modal .icon {
	font-size: 1.7rem;
	color: #4daf51;
}
.heading {
	color: green;
	font-weight: 300;
}
</style>
